/* Alle kaart kleuren en delays */

* {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#card1 {
  border-bottom: 5px solid rgb(114, 114, 255);
  animation-delay: 0;
}

#card1 add-button {
  background-color: rgb(114, 114, 255);
}

#card2 {
  border-bottom: 5px solid rgb(255, 81, 81);
  animation-delay: 100ms;
}

#card3 {
  border-bottom: 5px solid rgb(185, 65, 233);
  animation-delay: 200ms;
}

#card4 {
  border-bottom: 5px solid rgb(52, 185, 119);
  animation-delay: 100ms;
}

#card4 add-button {
  background-color: rgb(52, 185, 119);
}

#card5 {
  border-bottom: 5px solid rgb(223, 160, 67);
  animation-delay: 200ms;
}

/* Animaties */

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }

  to {
    opacity: 1;
  }
}

/* */

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #f0f1f4;
}

card {
  opacity: 0;
  animation: slideIn 0.4s forwards;
  min-width: 320px;
  border-radius: 5px;
  height: 170px;
  width: fit-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: end;
  cursor: pointer;
  transition: 0.5s;
}

card:hover {
  transition: 0.2s;
  filter: brightness(0.97) drop-shadow(5px 5px 5px #12121310);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}



main {
  margin-top: 75px;
  padding-bottom: 45px;
  max-width: 1300px;
  width: 100vw;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

card-top {
  margin-left: 40px;
  margin-right: 40px;
}

card-content {
  flex-wrap: wrap;
  justify-content: start;
  display: flex;
  padding: 10px;
  padding-left: 40px;
  padding-right: 0;
  margin-bottom: 10px;
}

title {
  display: block;
  width: 195px;
  opacity: 0.6;
  font-weight: 400;
  font-size: 13px;
  text-align: start;
}

top-text {
  opacity: 0.9;
  font-weight: 400;
  font-size: 17px;
}

amount {
  font-weight: 500;
  font-size: 30px;
}

row-item {
  height: 80px;
  align-items: start;
  display: flex;
  flex-direction: column;
}

add-button {
  transform: translatey(5px);
  background-color: rgb(114, 114, 255);
  width: 50px;
  right: 0;
  border-radius: 25px 0 5px 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

add-button:hover {
  filter: brightness(0.9);
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.add {
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  width: 25px;
  height: 25px;
  filter: invert(1);
}

.orange {
  filter: invert(57%) sepia(87%) saturate(336%) hue-rotate(357deg) brightness(101%) contrast(87%);
}

a {
  text-decoration: none;
}

row {
  display: flex;
  flex-direction: row;
}

column {
  display: flex;
  flex-direction: column;
}

row-item>button {
  width: 240px;
  outline: none;
  border: none;
  border: 3px rgb(223, 160, 67) solid;
  background-color: white;
  border-radius: 10px;
  margin-right: 10px;
  color: rgb(223, 160, 67);
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

row-item>button:hover {
  background-color: rgb(223, 160, 67);
  color: white;
  transition: 0.2s;
}

row-item>button:hover>.add {
  filter: invert(1);
}

.column>row-item {
  height: 45px;
}

.column {
  gap: 0;
  display: flex;
  flex-direction: column;
}

.add {
  height: 40px;
}

@keyframes alertSlide {
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 0.7;
  }
}

alert {
  transition: 0.4s ease-out;
  animation: alertSlide 0.4s ease-out;
  position: fixed;
  width: 250px;
  height: 45px;
  border-radius: 10px;
  background-color: rgb(45, 160, 103);
  opacity: 1;
  right: 10px;
  bottom: 10px;
  z-index: 100;
  color: white;
  display: flex;
  justify-content: left;
  padding-left: 50px;
  align-items: center;
  text-align: left;
}

alert img {
  height: 20px;
  width: 20px;
  pointer-events: none;
  user-select: none;
  left: 20px;
  position: absolute;
  filter: invert(1);
}

.sub-item {
  font-size: 13px;
  opacity: 0.5;
  color: black;
  text-decoration: underline;
}

#detailering-print {
  height: 20px;
  width: 20px;
  opacity: 0.7;
  position: absolute;
  transition: 0.3s;
  cursor: pointer;
  right: 15px;
}

#detailering-print:hover {
  opacity: 1;
}

#detailering {
  margin-top: 10px;
  height: calc(100% - 25px);
}

.editor-class {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.356);
  border-radius: 5px;
  padding: 5px;
}

.wrapper-class {
  height: inherit;
  display: flex;
  flex-direction: column;
}

.DraftEditor-root {
  height: calc(100% - 2px);
}

div.editor-class.rdw-editor-main>div>div>div>div * {
  margin: 0;
}

.wrapper-class {
  width: 100%;
  margin: 0 auto;
}

.toolbar-class {
  /* Ensures the dropdowns are displayed on top of other content */
  z-index: 2;
}

.sub-item.selected {
  color: rgb(0, 84, 211);
  font-weight: 500;
}

.list.detailering {
  height: 891px;
  padding-bottom: 0 !important;
}

#input-column {
  max-width: 700px;
}