@keyframes pulse {
    0% {
        transform: scale(0.33);
    }

    80%,
    100% {
        opacity: 0;
    }
}

@keyframes circle {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}

#bewerk-project {
    main {
        max-width: calc(100% - 30px);
        gap: 0;
        margin-top: 100px;
    }

    main>column {
        gap: 15px;
        width: 100%;
        margin-bottom: 50px;
    }

    header {
        box-shadow: none;
    }

    usertag {
        cursor: pointer;
        background-color: #7272ff;
        color: white;
        font-size: 11px;
        padding: 1px 11px 1px 11px;
        border-radius: 5px;
        display: flex;
        gap: 10px;
        position: relative;
        align-items: center;
        margin: 3px;
        margin-top: 0;
    }

    .align-end {
        justify-content: flex-end;
    }

    .full-width {
        width: calc(100vw - 30px);
        position: relative;
        display: flex;
    }

    .circle {
        position: relative;
        width: 10px;
        height: 10px;

        &:before {
            content: "";
            position: relative;
            display: block;
            width: 250%;
            height: 250%;
            box-sizing: border-box;
            margin-left: -75%;
            margin-top: -75%;
            border-radius: 45px;
            background-color: #75daad;
            animation: pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: #75daad;
            border-radius: 50px;
            animation: circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        }
    }

    .disabled-circle {
        &:before {
            content: "";
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            background-color: transparent;
            animation: none;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: #bed6cb;
            border-radius: 50px;
            animation: none;
        }
    }

    usertag img {
        width: 12px;
        margin-right: 5px;
        filter: invert(1);
        opacity: 1;
    }

    form textarea,
    form input,
    form select {
        height: 20px;
        width: 660px;
        border-radius: 5px;
        resize: none;
        border: none;
        padding: 10px;
        margin-bottom: 5px;
        font-family: "Poppins", sans-serif;
        border: 1px solid rgb(230, 230, 230);
        padding-left: 20px;
        padding-right: 20px;
    }

    .collapsible {
        font-weight: 700;
        position: relative;
        cursor: pointer;
        transition: 0.2s;
    }

    .collapsible:hover {
        opacity: 0.7;
    }

    tbody>*>*:nth-child(1):not(.collapsible)::before {
        content: "  ";
        width: 200px;
        padding-left: 20px;
    }

    select {
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none;
    }

    tbody>*:not(.collapsible).active {
        display: none;
    }

    .collapsible.active::after {
        content: "";
        position: absolute;
        margin-top: 7px;
        margin-left: 5px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        transform: rotate(-90deg);
        border-top: 8px solid rgb(25, 25, 25);
    }

    form select {
        height: 41px;
        width: 700px;
        display: flex;
        margin-bottom: 0;
    }

    form select~.overlay {
        transition: 0.1s;
    }

    form select.active~.overlay {
        transform: rotate(90deg);
    }

    .overlay {
        user-select: none;
        pointer-events: none;

        position: absolute;
        top: 14px;
        right: 23px;
        opacity: 0.8;
        width: 13px;
        height: 13px;
    }

    stack {
        height: 41px;
        position: relative;
    }

    form textarea:focus,
    form input:focus,
    form select:focus {
        outline: none;
        border-left: 3px rgb(114, 114, 255) solid;
        transform: translateX(-2px);
    }

    form input::placeholder {
        opacity: 0.6;
    }

    #left-column {
        width: 150px;
    }

    label {
        font-weight: 500;
        margin-top: 2px;
        font-size: 13px;
        position: relative;
    }

    .required::after {
        content: "*";
        color: rgb(248, 81, 81);
    }

    tr {
        max-width: 700px;
        min-width: 700px;
        margin-top: 2px;
        width: 100%;
        display: block;
    }

    column {
        width: 100%;
    }

    row {
        justify-content: space-between;
        width: 100%;
    }

    #invulvelden {
        width: 700px;
        height: 100%;
    }

    th,
    td {
        padding: 0;
        margin: 0;
        font-weight: 500;
        font-size: 15px;
        min-width: 40px;
        text-align: start;
        /* border: 1px solid black; */
    }

    td {
        font-weight: 400;
        position: relative;
    }

    table {
        background-color: white;
        border-radius: 5px;
        padding: 0;
        border-spacing: 0;
        padding-bottom: 13px;
    }

    input[type="checkbox"] {
        background-color: red;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        cursor: pointer;
        height: 100%;
        width: 23px;
        margin: 0;
        z-index: 2;
    }

    td>row>input:nth-child(3) {
        left: 25px;
    }

    td>row>span:nth-child(4) {
        left: 25px;
    }

    td>row>input:nth-child(5) {
        left: 50px;
    }

    td>row>span:nth-child(6) {
        left: 50px;
    }

    td>row>.checkbox:nth-child(2) {
        background-color: #ffffff;
    }

    td>row>.checkbox:nth-child(4) {
        background-color: #ffffff;
    }

    td>row>.checkbox:nth-child(6) {
        background-color: #ffffff;
    }

    input[type="checkbox"]:checked:nth-child(1)~.checkbox:nth-child(2) {
        background-color: #fc5a5a;
    }

    input[type="checkbox"]:checked:nth-child(3)~.checkbox:nth-child(4) {
        background-color: #ff9545;
    }

    input[type="checkbox"]:checked:nth-child(5)~.checkbox:nth-child(6) {
        background-color: #35b878;
    }

    td>row:nth-child(2) {
        padding-left: 30px;
    }

    .checkbox {
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        border-radius: 100px;
    }

    .checkbox {
        border: 1px solid rgba(0, 0, 0, 0.297);
    }

    tr>th:nth-child(1),
    td:nth-child(1) {
        border-left: none;
        width: 260px;
        opacity: 0.8;
        padding-left: 10px;
        padding-right: 10px;
    }

    tr>th:nth-child(2),
    td:nth-child(2) {
        width: 77px;
    }

    td:nth-child(3)>row {
        padding: 0;
        width: 60px;
        position: absolute;
        left: 21px;
        top: 5px;
        opacity: 1;
        display: none;
    }

    td:nth-child(3)>row>img {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }

    tr>th:nth-child(3),
    td:nth-child(3) {
        width: 100px;
    }

    tr>th:nth-child(4),
    td:nth-child(4) {
        width: 223px;
    }

    tr>th:nth-child(4),
    td:nth-child(4)>input {
        height: 20px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.356);
    }

    tr>th:nth-child(3) {
        padding-left: 12px;
        width: 88px;
    }

    td>* {
        width: 100%;
        background-color: transparent;
        outline: none;
        border: none;
    }

    buttons>button {
        background-color: white;
        outline: none;
        border: 1px solid rgb(213, 213, 213);
        width: 140px;
        height: 45px;
        border-radius: 8px;
        color: rgb(0, 0, 0, 0.8);
        font-weight: 500;
        font-size: 14px;
        transition: 0.3s;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    buttons>button:hover {
        opacity: 0.7;
    }

    buttons>button>img {
        opacity: 0.7;
        width: 14px;
    }

    buttons {
        height: 60px;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        gap: 15px;
        margin-bottom: 40px;
    }

    .title {
        font-weight: 500;
        font-size: 15px;
        opacity: 0.85;
    }

    .list {
        background-color: white;
        border-radius: 5px;
        padding: 15px;
        margin-left: 10px;
    }

    .history>item:not(.title) {
        text-decoration: underline;
        display: list-item;
        margin-left: 2em;
        cursor: pointer;
    }

    .margin-top {
        margin-top: 23px;
        padding-bottom: 0;
    }

    item {
        font-size: 13px;
        opacity: 0.5;
    }

    #evaluatie {
        margin-top: 20px;
    }

    .inputs {
        justify-content: space-between;
        width: 50%;
    }

    .inputs>column>input {
        width: 309.5px;
    }

    .long {
        margin-left: 100px;
        width: 222px;
        border: 1px solid rgba(0, 0, 0, 0.356);
        border-radius: 4px;
        height: 20px;
    }

    .extra-long {
        width: 936px !important;
    }

    .extra-long-td {
        width: 960px !important;
    }

    .extra-long~*::before {
        padding: 0;
    }

    .big-height {
        height: 100px;
    }

    textarea {
        font-family: "Poppins", sans-serif;

        resize: none;
    }

    autocomplete {
        position: absolute;
        display: none;
        flex-direction: column;
        top: 60px;
        z-index: 2;
        max-height: 350px;
        overflow-y: scroll;
    }

    autocomplete.active {
        display: flex;
    }

    suggestion {
        background-color: white;
        width: 466px;
        padding: 10px;
        height: 15px;
        align-items: center;
        display: flex;
        cursor: pointer;
    }

    suggestion.focus {
        filter: brightness(0.9);
    }

    suggestion:hover {
        filter: brightness(0.9);
    }

    input[type="file"] {
        display: none;
    }

    .file-upload {
        width: 100px;
        margin: 0;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
    }

    .file-upload img {
        margin-left: 5px;
        height: 12px;
    }

    p {
        margin: 0;
        opacity: 0.5;
        font-size: 13px;
    }

    #main-right {
        width: 100%;
    }

    textarea {
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        width: calc(100% - 20px);
        height: 100%;
        border: 1px solid rgba(0, 0, 0, 0.356);
        outline: none;
        border-radius: 5px;
        margin-top: 10px;
    }

    .beurs-list {
        margin-left: 0;
        width: 250px;
        margin-right: 10px;
        padding-bottom: 15px;
    }

    #main-row {
        width: 100%;
    }

    form:nth-child(1) {
        margin-bottom: 10px;
    }

    .sub-item {
        margin-left: 30px;
    }

    .sub-item::before {
        display: inline-block;
        text-decoration: none;

        margin-right: 5px;
        content: "•";
    }
}