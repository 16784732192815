header {
  height: 60px;
  width: 100%;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.116);
  position: fixed;
  z-index: 101;
}

bottomheader {
  position: fixed;
  top: 60px;
  width: 100vw;
  background-color: #ffffff;
  z-index: 100;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

nav {
  user-select: none;
  width: calc(100% - 90px);
  position: absolute;
  display: flex;
  left: 50%;
  justify-content: space-between;
  transform: translate(-50%);
}

right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}

left {
  font-weight: 700;
  display: flex;
  align-items: center;
}

nav-button {
  background-color: transparent;
  border: none;
  font-size: 14px;
  color: rgb(97, 97, 97);
  cursor: pointer;
  transition: 0.2s;
  padding: 20px;
}

nav-button:hover {
  color: rgb(38, 38, 38);
}

header #logo {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-right: 20px;
}

#profile-image {
  height: 40px;
  width: 40px;
  border-radius: 3px;
  filter: brightness(1) drop-shadow(0 4px 5px rgba(0, 0, 0, 0.15));
  transition: 0.4s;
  cursor: pointer;
  padding: 20px;
}

#profile-image:hover {
  filter: brightness(0.9) drop-shadow(0 6px 7px rgba(0, 0, 0, 0.15));
}

#profile-popup {
  position: absolute;
  top: 70px;
  right: 15px;
  width: 300px;
  height: 200px;
  background-color: white;
  border-radius: 5px;
  filter: drop-shadow(0 6px 7px rgba(0, 0, 0, 0.15));
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  padding: 20px;
}

#profile-img {
  height: 70px;
  width: 70px;
  border-radius: 70px;
  filter: drop-shadow(0 6px 7px rgba(0, 0, 0, 0.1));
  margin-top: 10px;
}

#profile-welcome {
  margin-top: 10px;
  font-weight: 500;
  color: rgb(54, 54, 54);
  font-size: 15px;
}

#profile-email {
  margin-top: 5px;
  font-weight: 400;
  color: rgb(54, 54, 54);
  font-size: 13px;
  margin-bottom: 0;
}

#profile-email {
  color: rgb(104, 104, 104);
  margin-top: 0;
}

#logout-button {
  margin-top: 20px;
  border: none;
  border-radius: 3px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: white;
  color: #172762;
  font-weight: 500;
  border: 1px solid #2e4896;
}

#logout-button:hover {
  transition: 0.3s;
  background-color: #172762;
  color: white;
}