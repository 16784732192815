#login {

    #lock {
        width: 40px;
        height: 40px;
        display: block;
        margin-bottom: 20px;
        opacity: 0.5;
    }

    form {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 290px;
        height: 300px;
    }

    form input {
        font-family: "Poppins", sans-serif;
        border-radius: 5px;
        padding-inline: 0;
        border: none;
        background-color: white;
        padding-block: 0;
        height: 40px;
        width: 250px;
        margin: 5px;
        padding-left: 20px;
        padding-right: 20px;
        display: block;
        outline: none;
    }

    form input::placeholder {
        opacity: 0.5;
    }

    form input:nth-child(4) {
        width: 290px;
        cursor: pointer;
        transition: 0.3s;
    }

    form input:nth-child(4):hover {
        filter: brightness(0.8);
    }

    body {
        background-color: rgb(237, 237, 237);
    }
}