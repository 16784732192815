#planning {
  form textarea,
  form input,
  form select {
    height: 20px;
    width: 930px;
    border-radius: 5px;
    resize: none;
    border: none;
    padding: 10px;
    margin-bottom: 5px;
    font-family: "Poppins", sans-serif;
    border: 1px solid rgb(230, 230, 230);
    padding-left: 20px;
    padding-right: 20px;
  }

  main {
    max-width: calc(100% - 30px);
  }

  .collapsible {
    font-weight: 700;
    position: relative;
    cursor: pointer;
    transition: 0.2s;
  }

  .collapsible:hover {
    opacity: 0.7;
  }

  .collapsible::after {
    content: "";
    position: absolute;
    margin-top: 7px;
    margin-left: 5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 8px solid rgb(25, 25, 25);
    transition: 0.3s;
  }

  tbody > * > *:nth-child(1):not(.collapsible)::before {
    content: "  ";
    width: 200px;
    padding-left: 20px;
  }

  select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
  }

  tbody > *:not(.collapsible).active {
    display: none;
  }

  .collapsible.active::after {
    content: "";
    position: absolute;
    margin-top: 7px;
    margin-left: 5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transform: rotate(-90deg);
    border-top: 8px solid rgb(25, 25, 25);
  }

  form select {
    height: 41px;
    width: 970px;
    display: flex;
    margin-bottom: 0;
  }

  form select ~ .overlay {
    transition: 0.1s;
  }

  form select.active ~ .overlay {
    transform: rotate(90deg);
  }

  .overlay {
    user-select: none;
    pointer-events: none;

    position: absolute;
    top: 14px;
    right: 23px;
    opacity: 0.8;
    width: 13px;
    height: 13px;
  }

  stack {
    height: 41px;
    position: relative;
  }

  form textarea:focus,
  form input:focus,
  form select:focus {
    outline: none;
    border-left: 3px rgb(114, 114, 255) solid;
    transform: translateX(-2px);
  }

  form input::placeholder {
    opacity: 0.6;
  }

  #left-column {
    width: 140px;
  }

  label {
    font-weight: 500;
    margin-top: 14px;
    font-size: 13px;
    cursor: pointer;
  }

  .required::after {
    content: "*";
    color: rgb(248, 81, 81);
  }

  tr {
    width: 100%;
    display: block;
  }

  column {
    width: 1000px;
  }

  row {
    justify-content: space-between;
    width: 1300px;
  }

  th,
  td {
    padding: 0;
    border-bottom: 1px solid rgba(189, 189, 189, 0.151);
    border-left: 1px solid rgb(189, 189, 189);
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    min-width: 40px;
    text-align: start;
  }

  td {
    font-weight: 400;
  }

  table {
    background-color: white;
    border-radius: 5px;
    padding: 0;
    border-spacing: 0;
    border: 1px solid rgb(150, 150, 150);
  }

  tr > th:nth-child(5),
  td:nth-child(5) {
    width: 268px;
  }

  td > * {
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
  }

  input[type="checkbox"] {
    background-color: rgb(114, 114, 255);
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
    height: 100%;
    width: 70px;
    margin: 0;
    z-index: 2;
  }

  .checkbox {
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 70px;
    background-color: #f84e4e;
  }

  td:hover input ~ .checkbox {
    background-color: #f84e4e;
  }

  input[type="checkbox"]:checked ~ .checkbox {
    background-color: #35b878;
  }

  #add-button {
    background-color: white;
    outline: none;
    border: 1px solid rgb(213, 213, 213);
    width: 140px;
    height: 45px;
    margin-bottom: 40px;
    border-radius: 8px;
    color: rgb(0, 0, 0, 0.8);
    margin-left: auto;
    font-weight: 500;
    font-size: 14px;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  #add-button:hover {
    opacity: 0.8;
  }

  #add-icon {
    opacity: 0.7;

    margin-right: -10px;
    height: 14px;
    width: 14px;
  }

  .history > item.title {
    margin-bottom: 10px;
  }

  .history > item:not(.title) {
    text-decoration: underline;
    display: list-item;
    margin-left: 2em;
    cursor: pointer;
  }

  .margin-top {
    margin-top: 30px;
    padding-bottom: 0;
  }

  item {
    opacity: 0.5;
  }

  #evaluatie {
    margin-top: 20px;
  }

  .inputs {
    justify-content: space-between;
    width: 970px;
  }

  .inputs > column {
    width: 485px;
  }

  .inputs > column > input {
    width: 444px;
  }

  .long {
    width: 580px;
  }

  .extra-long {
    width: 936px !important;
  }

  .extra-long-td {
    width: 960px !important;
  }

  .extra-long ~ *::before {
    padding: 0;
  }

  .big-height {
    height: 100px;
  }

  textarea {
    font-family: "Poppins", sans-serif;

    resize: none;
  }

  card img {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center center;
  }

  #beurs-image:before {
    content: " ";
    display: block;
    position: absolute;
    height: 140px;
    width: 140px;
    background-image: url("..\\..\\assets\\unknown.jpg");
  }

  card {
    opacity: 1;
    transform: none;
    animation: none;
    height: 140px;
    width: 100%;
    justify-content: start;
    align-items: start;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    position: relative;
    cursor: default;
    margin: 0;
    display: none;
  }

  .date::before {
    content: "";
    display: inline-block;
    width: 9px;
    margin-bottom: -1px;
    margin-right: 3px;
    height: 9px;
    background-repeat: no-repeat;
    opacity: 0.8;
    background-image: url("..\\..\\assets\\date.svg");
  }

  .title {
    font-weight: 400;
    font-size: 12px;
  }

  .date {
    opacity: 0.5;
    font-size: 9px;
  }

  .location {
    font-size: 9px;
    opacity: 0.5;
  }

  .source {
    font-size: 9px;
    opacity: 0.5;
  }

  .location::before {
    content: "";
    display: inline-block;
    width: 9px;
    margin-bottom: -1px;
    margin-right: 3px;
    height: 9px;
    background-repeat: no-repeat;
    opacity: 0.8;
    background-image: url("..\\..\\assets\\location.svg");
  }

  .source::before {
    content: "";
    display: inline-block;
    width: 9px;
    margin-bottom: -1px;
    margin-right: 3px;
    height: 9px;
    background-repeat: no-repeat;
    opacity: 0.8;
    background-image: url("..\\..\\assets\\folder-solid.svg");
  }

  card:hover {
    filter: none;
  }

  project {
    display: flex;
    height: 140px;
    width: 100%;
    background-color: white;
  }

  card column {
    width: 180px;
    padding-left: 10px;
    height: 140px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  labels {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 140px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    background-color: white;
    overflow-y: scroll;
    width: 141px;
  }

  labels label {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 5px;
    margin-top: 4px;
    color: white;
    font-size: 10px;
    background-color: rgb(114, 114, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 30px;
  }

  main > column {
    gap: 15px;
    width: 100%;
    margin-bottom: 50px;
  }

  form {
    cursor: default;
  }

  table {
    border: none;
    border-radius: 0;
  }

  tbody {
    display: flex;
    flex-direction: column;
    height: 140px;
  }

  .collapsible::after {
    border-top: none;
  }

  .date-table > tbody > tr:nth-child(1) > td {
    line-height: 10px;
    font-size: 9px;
    text-align: center;
  }

  th,
  td {
    width: 10px;
  }

  table:not(.date-table) > tbody > tr > th:nth-child(1),
  table:not(.date-table) > tbody > tr > td:nth-child(1) {
    border-left: none;
    display: flex;
    align-items: center;
    width: 120px;
    opacity: 1;
    font-size: 12px;
    height: 18.5px;
  }

  .date-field {
    width: 75px;
    height: 19.6px;
    text-align: left;
    padding-left: 5px;
    font-size: 12px;
  }

  tr > th:nth-child(4),
  td:nth-child(4) {
    width: 10px;
  }

  tr > th:nth-child(5),
  td:nth-child(5) {
    width: 10px;
  }

  tr > th:nth-child(6),
  td:nth-child(6) {
    width: 10px;
  }

  tr {
    min-width: 0;
    width: 100%;
    display: block;
  }

  tooltip {
    position: absolute;
    display: none;
    background-color: white;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.15));
    width: auto;
    padding-left: 15px;
    white-space: nowrap;
    padding-right: 15px;
    transform: translate(-50%, -35px);
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    z-index: 100;
  }

  .date-table > tbody > tr:not(:nth-child(1)) > td {
    display: none;
  }

  .date-table > tbody > tr:not(:nth-child(1)) > td:hover {
    background-color: rgb(234, 234, 234);
  }

  td:hover > tooltip {
    display: flex;
  }

  td:hover > tooltip {
    display: flex;
  }

  .date-table > tbody > tr > * {
    min-width: 6px;
    border-left: 1px solid rgba(189, 189, 189, 0.25);
    border-bottom: 1px solid rgba(189, 189, 189, 0.25);
    height: 18.4px;
  }

  .date-table > tbody > tr:nth-child(1) > * {
    align-items: center;
    justify-content: center;
    min-width: 48px;
    border-left: 1px solid rgba(189, 189, 189, 0.25);
    border-bottom: 1px solid rgba(189, 189, 189, 0.25);
    height: 23px;
  }

  tbody > * > *:nth-child(1):not(.collapsible)::before {
    content: "";
    width: 0;
    padding-left: 0;
  }

  .date-table > tbody {
    overflow-x: scroll;
    height: 144.5px;
    position: absolute;
    top: 0;
    width: calc(100% - 600px);
    height: calc(100% + 5px);
  }

  /* Improve table speed */
  table {
    table-layout: fixed;
  }

  .date-table {
    min-width: calc(100% - 600px);
    height: 100%;
  }

  tr:nth-child(8) > td {
    border-bottom: none;
  }

  card > table:nth-child(4) > tbody > tr:nth-child(2) > td {
    border-top: 4px solid white;
  }

  /* width */
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .label-add {
    background-color: transparent;
    border: 2px solid rgb(165 165 165);
    height: 12px;
    transition: 0.2s;
  }

  .label-add > img {
    transition: 0.2s;
    width: 12px;
    height: 12px;
    opacity: 0.6;
  }

  .label-add:hover {
    background-color: rgb(165 165 165);
  }

  .label-add:hover img {
    filter: invert(1);
    opacity: 1;
  }

  .visible {
    display: flex;
  }

  .hidden {
    display: none;
  }

  lock {
    cursor: pointer;
    display: block;
    height: 10px;
    margin-right: 4px;
    width: 8px;
    background: url("..\\..\\assets\\lock-solid.svg");
    opacity: 0.8;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  lock:hover {
    background: url("..\\..\\assets\\unlock-solid.svg");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  lock.unlocked {
    margin-right: 2px;
    width: 10px;
    background: url("..\\..\\assets\\lock-open-solid.svg");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .fullWidth {
    width: 100%;
  }

  td.now {
    border-right: 1px solid rgba(0, 110, 255, 0.2) !important;
    border-left: 1px solid rgba(0, 110, 255, 0.2) !important;
    background-color: rgba(0, 110, 255, 0.05);
  }
}
